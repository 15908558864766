<script>
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';

	export let countries = [];

	let modalOpen = false;
	let selectedLang = $page.params.lang;

	$: filteredCountries = countries.filter((lang) => lang !== selectedLang);

	function getCountryFlag(lang) {
		if (lang === 'fi') return `fi fi-fi`;

		if (lang === 'se') return `fi fi-se`;

		return `fi fi-gb`;
	}

	async function setCountry(lang) {
		const pathname = $page.url.pathname;

		const updatedPathname = pathname.replace(selectedLang, lang);

		await goto(updatedPathname);

		selectedLang = lang;
	}
</script>

<div class="wrapper" on:click={() => (modalOpen = !modalOpen)}>
	<div class="option">
		<span class={`${getCountryFlag(selectedLang)}`} />
	</div>

	{#if modalOpen}
		<div class="modal">
			{#each filteredCountries as country}
				<div class="option" on:click={() => setCountry(country)}>
					<span class={`${getCountryFlag(country)}`} />
				</div>
			{/each}
		</div>
	{/if}
</div>

<style>
	.modal {
		position: fixed;
		right: 12px;
		bottom: 72px;
		z-index: 99;
		display: grid;
		place-content: center;
		grid-gap: 12px;
		border-radius: 50%;
	}

	.wrapper {
		position: fixed;
		bottom: 12px;
		right: 12px;
		display: grid;
		place-items: center;
		z-index: 99;
	}

	.option {
		border-radius: 50%;
		background-color: var(--gf-grey-dark);
		width: 48px;
		height: 48px;
		display: grid;
		place-items: center;
		z-index: 99;
	}

	.option:hover {
		background-color: var(--gf-grey-light);
		cursor: pointer;
	}

	@media screen and (min-width: 992px) {
		.modal {
			grid-gap: 24px;
			bottom: 102px;
		}

		.option {
			width: 66px;
			height: 66px;
		}

		.option span {
			font-size: 22px;
		}
	}
</style>
