<script context="module">
	import { browser } from '$app/env';
	import { locale } from '$utils/appwrite';

	export async function load({ params, url }) {
		const { lang, orderId } = params;

		if (browser && !lang) {
			const userLocale = await locale.get();
			const countryCode = userLocale?.countryCode;

			if (countryCode === 'FI') {
				return {
					status: 302,
					redirect: `/fi/book`
				};
			} else if (countryCode === 'SE') {
				return {
					status: 302,
					redirect: `/se/book`
				};
			} else {
				return {
					status: 302,
					redirect: `/en/book`
				};
			}
		} else if (lang === 'sv') {
			const redirect = url.pathname.replace(lang, 'se');

			return {
				status: 302,
				redirect
			};
		} else if (!acceptedCountry.includes(lang) && orderId) {
			const redirect = url.pathname.replace(lang, 'en');

			return {
				status: 302,
				redirect
			};
		} else {
			return {};
		}
	}
</script>

<script>
	import 'normalize.css';
	import '$utils/app.css';
	import { acceptedCountry } from '$utils/helpers';
	import CountryPicker from '$lib/CountryPicker.svelte';
	import * as Sentry from '@sentry/svelte';
	import { BrowserTracing } from '@sentry/tracing';
	import '/node_modules/flag-icons/css/flag-icons.min.css';

	Sentry.init({
		environment: import.meta.env.VITE_SENTRY_ENV,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0
	});
</script>

<CountryPicker countries={acceptedCountry} />

<div class="wrapper">
	<slot />
</div>

<style>
	.wrapper {
		display: grid;
		padding: 0 24px;
		box-sizing: border-box;
	}
</style>
